import React from 'react';
import Service from './Service';
import Spinner from '../../Shared/Spinner/Spinner';

const predefinedServices = [
  {
    _key: '1',
    name: 'Website Development',
    description: 'We offer top-notch website development services.',
  },
  {
    _key: '2',
    name: 'Web Design',
    description: 'Our web design services are creative and user-friendly.',
  },
  {
    _key: '3',
    name: 'Email Marketing',
    description: 'Effective email marketing campaigns to boost your business.',
  },
  {
    _key: '4',
    name: 'SEO',
    description: 'Improve your website\'s search engine rankings with our SEO expertise.',
  },
  {
    _key: '5',
    name: 'Social Media Marketing',
    description: 'Engage with your audience through social media marketing.',
  },
  {
    _key: '6',
    name: 'Business Data Handling',
    description: 'Secure and efficient handling of your business data.',
  },
  {
    _key: '7',
    name: 'Many More Services',
    description: 'Explore a wide range of services to meet your business needs.',
  },
];

const Services = () => {
  return (
    <section id="services" className="services">
      <h4 className="miniTitle text-center">SERVICES</h4>
      <div className="text-center">
        <h5 className="text-center sectionTitle">WE PROVIDE AWESOME SERVICES</h5>
      </div>
      <div className="row mt-4 container mx-auto justify-content-center">
        {predefinedServices.map((service, id) => (
          <Service key={service._key + id} service={service} />
        ))}
      </div>
    </section>
  );
};

export default Services;
