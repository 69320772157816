export const PricingData = [
    [
        {title: 'Basic' ,name: 'Web Design', price: 48,desc:"Our Basic Web Design plan offers a clean and functional website with essential features, perfect for startups and small businesses looking to establish an online presence. "},
        {title: 'Standard' ,name: 'Web Design', price: 78,desc:"Elevate your online presence with our Standard Web Design plan. It includes advanced features, responsive design, and enhanced user experience, tailored for growing businesses."},
        {title: 'Premium' ,name: 'Web Design', price: 98,desc:" Our Premium Web Design plan offers top-tier customization, e-commerce integration, SEO optimization, and ongoing support. Ideal for businesses seeking a robust and high-impact online presence."},
    ],
    [
        {title: 'Basic' ,name: 'Email Marketing', price: 39,desc:"Our Basic plan is perfect for small businesses and startups. Send engaging email campaigns, access essential analytics, and grow your audience with ease."},
        {title: 'Standard' ,name: 'Email Marketing', price: 79,desc:"Upgrade to our Standard plan for advanced features. Enjoy automation, A/B testing, and personalized content to maximize your email marketing impact."},
        {title: 'Premium' ,name: 'Email Marketing', price: 99,desc:"Unlock the full potential of email marketing with our Premium plan. Get dedicated support, detailed insights, and robust integration options for seamless, high-performance campaigns."},
    ],
    [
        {title: 'Basic' ,name: 'Social Media Marketing', price: 36,desc:"Our Basic Plan is the perfect starting point to kickstart your social media presence. It includes essential content creation and posting on select platforms to engage your audience."},
        {title: 'Standard' ,name: 'Social Media Marketing', price: 56,desc:"Elevate your social media game with our Standard Plan. Enjoy regular content updates, strategic ad campaigns, and performance analytics, tailored to boost your brand's reach and impact."},
        {title: 'Premium' ,name: 'Social Media Marketing', price: 86,desc:"Our Premium Plan is the ultimate solution for dominating social media. Unlock a comprehensive package featuring top-tier content, advanced ad targeting, influencer partnerships, and in-depth data analysis for unparalleled results.        "},
    ],
    [
        {title: 'Basic' ,name: 'Web Development', price: 49,desc:"Our Basic plan offers essential web development services, perfect for startups and small businesses. Get a clean, functional website that establishes your online presence."},
        {title: 'Standard' ,name: 'Web Development', price: 69,desc:"Elevate your online identity with our Standard plan. Enjoy custom design, responsive pages, and SEO optimization. Ideal for businesses looking to expand their digital footprint."},
        {title: 'Premium' ,name: 'Web Development', price: 89,desc:"Experience the pinnacle of web development with our Premium plan. Gain a fully tailored website, advanced features, e-commerce integration, and top-tier SEO for maximum online success. Perfect for enterprises and ambitious ventures."},
    ],
    [
        {title: 'Basic' ,name: 'SEO', price: 44,desc:"Our cost-effective Basic plan offers essential on-page optimization, keyword research, and monthly reporting to help small businesses boost their online presence."},
        {title: 'Standard' ,name: 'SEO', price: 77,desc:"The Standard plan combines on-page and off-page strategies, advanced keyword research, and competitor analysis for medium-sized enterprises aiming for sustained growth."},
        {title: 'Premium' ,name: 'SEO', price: 88,desc:"Our Premium plan is a comprehensive solution, featuring custom strategies, in-depth analytics, high-quality content creation, and premium link-building, tailored for large businesses seeking top-tier search engine rankings and visibility."},
    ],
    [
        {title: 'Basic' ,name: 'UI Design', price: 45,desc:"Get started with our cost-effective Basic Plan, perfect for small projects. You'll receive a clean and functional UI design tailored to your needs."},
        {title: 'Standard' ,name: 'UI Design', price: 75,desc:"Our Standard Plan offers an enhanced UI design package, ideal for growing businesses. Enjoy custom-designed interfaces with added features and style."},
        {title: 'Premium' ,name: 'UI Design', price: 95,desc:"Elevate your brand with our Premium Plan, designed for enterprises. Experience top-tier UI design with advanced functionality, unique aesthetics, and dedicated support."},
    ]
]