import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './Navbar.css';
import twinkleImage from './twinkle.png';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useAppContext } from '../../../context';

const NavBar = () => {
    const { state: { user } } = useAppContext();
    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        });
    }, []);

    const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    const closeMenu = () => {
        const navToggle = document.querySelector(".navbar-toggler");
        if (navToggle) {
            navToggle.click(); // Programmatically click the navbar toggle button to close the menu
        }
    };

    return (
        <Navbar className={`navbar navbar-expand-lg navbar-light ${isSticky ? "navStyle" : "navDefault"}`} expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={scrollTop} className="navBrn">
                    <img src={twinkleImage} alt="Twinkle" className="brnIcon" id="responsiveImage" />
                    onTop <span className="navHighlight">Media</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto mainNav" activeKey="/home">
                        <Nav.Item>
                            <Nav.Link as={Link} to="/" className="nav-link" onClick={() => { scrollTop(); closeMenu(); }}>Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#services" className="nav-link" onClick={closeMenu}>Services</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#testimonial" className="nav-link" onClick={closeMenu}>Reviews</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#contact" className="nav-link" onClick={closeMenu}>Contact Us</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
